import React from 'react';
import './Footer.css';
import { BsFacebook, BsTwitter, BsLinkedin } from 'react-icons/bs'
import {ReactComponent as HealthBuddyLogo} from '../../assets/images/logoImageWhite.svg'

const Footer = () => {
  return (
    <footer id="contact">
      <div className="row align-items-center">
        <div className="col-md-3 col-12">
          <HealthBuddyLogo className="img-fluid navbar-brand"/>
        </div>
        <div className="col-md-8 col-12 ">
        </div>
        {/* <div className="col-md-2 col-12 text-center text-md-end fs-4"><a href="https://twitter.com/GetHealthBuddy" target="_blank" rel="noopener noreferrer"><BsTwitter /></a></div> */}
      </div>
      <div className="row">

        <div className="col-md-4 col-12">
          <ul className="info-contact">
            <li> <span>Contact us:</span></li>
            <li><a href="mailto:support@healthbuddy.xyz">Email: support@healthbuddy.xyz</a></li>
            <li>Made with ❤️️ in the USA</li>
          </ul>
        </div>
        <div className="rights">
          <p>© 2024 HealthBuddy, Inc. All Rights Reserved.</p>
          <p><a href="/terms" >Terms of Service</a></p>
          <p><a href="/privacy" >Privacy Policy</a></p>

        </div>
      </div>
    </footer>
  )
}

export default Footer