import React, { useState } from 'react';
import axios from 'axios';
import ImageScroll from '../../components/ImageScroll/ImageScroll';
import { BsArrowRight, BsXCircle } from "react-icons/bs";
import './Hero.css';

import {ReactComponent as HeroImage} from '../../assets/images/heroImage.svg'
import {ReactComponent as ScheduledReminders} from '../../assets/images/scheduledRemindersImage.svg'
import {ReactComponent as SafetyMonitoring} from '../../assets/images/safetyMonitoringImage.svg'

import { BACKEND_URL } from '../../utils';

import Waveform from '../Waveform/Waveform';
import conversationDemo from '../../assets/audio/conversation.mp3';

const Hero = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const validateEmail = ( email:string ) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError(true);
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await axios.post( `${BACKEND_URL}/v0/landing/addEmail`, { email });
      if (response.status === 200) {
        setError(false);
        setMessage('You have been added to the waitlist 🎉');
      } else {
        setError(true);
        setMessage('An error occurred — please try again later 😥');
      }
    } catch (error) {
      setError(true);
      setMessage('An error occurred — please try again later 😥');
    }
  };

    // Function to hide the message
    const handleCloseMessage = () => {
      setMessage('');
      setError(false);
    };

  // const messageStyle: React.CSSProperties = {
  //   // position: 'absolute', // This will overlay the content without affecting the layout
  //   top: '20px', // Adjust as needed for your layout
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   zIndex: 1000, // Ensure it's above other content
  //   padding: '1rem',
  //   border: '2px solid', // Neobrutalism often employs solid, chunky borders
  //   borderColor: error ? '#191A23' : '#191A23', // Red for error, green for success
  //   backgroundColor: error ? '#FF5454' : '#B9FF66', // Same as border color or any color fitting your palette
  //   color: '#191A23',
  //   fontWeight: 'bold',
  //   textAlign: 'center',
  //   width: '90%', // or '600px' or whatever fits your design
  //   maxWidth: '600px',
  //   boxSizing: 'border-box',
  //   position: 'fixed',
  //   boxShadow: '6px 6px #191A23',
  //   borderRadius: '12px'
  // };

  // // Style for the close button
  // const closeButtonStyle: React.CSSProperties = {
  //   position: 'absolute',
  //   top: '0.5rem',
  //   right: '1rem',
  //   border: 'none',
  //   background: 'none',
  //   color: '#191A23',
  //   cursor: 'pointer',
  //   fontSize: '1.5rem',
  // };

  return (
    <div className="hero" id="hero">
      <div className="row align-items-center">
        <div className="col-md-6 col-12 hero-col">
          {/* <h1 className="title"> Spend less time figuring out how to operate a startup </h1> */}
          <h1 className="title"> Daily medication, meal, activity, and safety reminders </h1>
          <p className="py-4 subtitle">HealthBuddy helps you protect the wellness and safety of the people you love by providing an easy to use platform for creating and monitoring daily reminders and check-ins via call or text.</p>
          <div className="row">
            <div className="col-lg-6 col-12">
              <input 
                type="email"
                className="waitlist-input"
                placeholder="name@example.com" 
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-lg-6 col-12">
              <button className="hero-btn" onClick={handleSubmit}>Join the waitlist <BsArrowRight /></button>
            </div>
            {/* {message && (
              <div style={messageStyle}>
                <button className="close-button-style" onClick={handleCloseMessage}>
                  <BsXCircle />
                </button>
                {message}
              </div>
            )} */}

                        {message && (
              <div className={error ? 'message-style message-style-error' : 'message-style'}>
                <button className="close-button-style" onClick={handleCloseMessage}>
                  <BsXCircle />
                </button>
                {message}
              </div>
            )}
          </div>
          {/* <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input> */}
          {/* <a href="#" target="_blank" rel="noopener noreferrer"><button className="hero-btn">Join the waitlist <BsArrowRight /></button></a> */}
          {/* <a href="" target="_blank" rel="noopener noreferrer"><button className="hero-btn"><span>Join the waitlist </span></button></a> */}
          {/* <button className="hero-btn"><span>Join the waitlist </span></button> */}
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4 hero-image">
          <HeroImage className='img-fluid'/>      
        </div>
      </div>
      
      <div className="centered-heading">
        <h3>HealthBuddy is the modern solution for wellness check-ins</h3>
      </div> 
      <div className="headings" style={{margin:"0", marginBottom: "2rem", justifyContent:"center"}}>
        <p>Listen to HealthBuddy in action!</p>
      </div> 
      <div className="container" style={{paddingLeft: "10%", paddingRight: "10%"}}>
        <Waveform audio={conversationDemo} />
      </div>

      {/* <div className="d-flex justify-content-center">
        <div className="p-2-custom">
          <ScheduledReminders />
        </div>
        <div className="p-2-custom">
          <SafetyMonitoring />
        </div>
      </div> */}

      {/* <div className="clients">

        <ScheduledReminders key="0" />
        <SafetyMonitoring key="1" />
          
      </div> */}
      {/* <ImageScroll /> */}
    </div>
    
  )
}

export default Hero