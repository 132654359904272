import Navigation from "../components/Navigation/Navigation"
import Hero from "../components/Hero/Hero"
import Demo from "../components/Demo/Demo"
import HowItWorks from "../components/HowItWorks/HowItWorks"
import Benefits from "../components/Benefits/Benefits"
import Services from "../components/Services/Services"
import Pricing from "../components/Pricing/Pricing"
import FAQ from "../components/FAQ/FAQ"
import Footer from "../components/Footer/Footer"

const LandingPage = () => {
    return(
      <div className="container">
        <Navigation />
        <Hero />
        {/* <Demo /> */}
        <HowItWorks />
        <Benefits />
        <Services />
        <Pricing />
        <FAQ />
        <Footer />
      </div>
    )
  }

export default LandingPage;