import React from 'react'
import Headings from '../../components/Headings/Headings';
// import { data } from '../../constants';
import { BsLinkedin } from 'react-icons/bs';
import './HowItWorks.css';

import howItWorks1 from '../../assets/img/howItWorks1.png'
import {ReactComponent as TokyoCalendar} from '../../assets/images/tokyoCalendar.svg'
import {ReactComponent as TokyoDate} from '../../assets/images/tokyoDate.svg'
import {ReactComponent as TokyoAlert} from '../../assets/images/tokyoAlert.svg'

const HowItWorks = () => {
  return (
    <div className="section-padding" id="how-it-works">
      <Headings title="How HealthBuddy Works" text="Getting started with HealthBuddy only takes a few minutes, and we begin sending your reminders and check-ins immediately." />

      <div className="row">

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="0">
              <div className="card-team-header"> 
              {/* <img src={howItWorks1} /> */}
              <TokyoCalendar className="how-it-works-svg" />
              </div>
              <p className="text-center">Subscribe to HealthBuddy and create your customized reminder or safety check-in schedule</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="1">
              <div className="card-team-header"> 
              {/* <img src={howItWorks1} /> */}
              <TokyoDate className="how-it-works-svg-2" />
              </div>
              <p className="text-center">We call or text according to your schedule and follow up to ensure that the message was received</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="2">
              <div className="card-team-header"> 
              {/* <img src={howItWorks1} /> */}
              <TokyoAlert className="how-it-works-svg" />
              </div>
              <p className="text-center">You enjoy peace of mind knowing that emergency contacts will be alerted if a reminder or check-in is missed</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks; 
