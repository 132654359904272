import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';
import {ReactComponent as PauseCircle} from '../../assets/images/pause-circle.svg'
import {ReactComponent as PlayCircle} from '../../assets/images/play-circle.svg'

import './Waveform.css'

// Define the props interface
interface WaveformProps {
  audio: string;
}

const Waveform: React.FC<WaveformProps> = ({ audio }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const waveSurferRef = useRef<WaveSurfer | { isPlaying: () => boolean }>({
    isPlaying: () => false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const waveSurfer = WaveSurfer.create({
        container: containerRef.current,
        // responsive: true,
        barWidth: 3,
        barHeight: 1,
        cursorWidth: 0,
        waveColor: "#191A23",
        progressColor: "#B9FF66"
      });
      waveSurfer.load(audio);
      waveSurfer.on('ready', () => {
        waveSurferRef.current = waveSurfer;
      });

      return () => {
        waveSurfer.destroy();
      };
    }
  }, [audio]);

  return (
    <WaveSurferWrap>
      <button
        onClick={() => {
          if ('playPause' in waveSurferRef.current) {
            waveSurferRef.current.playPause();
            toggleIsPlaying(waveSurferRef.current.isPlaying());
          }
        }}
        type="button"
        style={{}}
      >
        {isPlaying ? <PauseCircle className="waveform-button"/> : <PlayCircle className="waveform-button"/>}
      </button>
      <div ref={containerRef} />
    </WaveSurferWrap>
  );
};

Waveform.propTypes = {
  audio: PropTypes.string.isRequired,
};

const WaveSurferWrap = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;

  button {
    width: 30px;
    height: 30px;
    border: none;
    padding: 0;
    background-color: white;
  }
`;

export default Waveform;