import React from 'react'
import Headings from '../../components/Headings/Headings';
import '../HowItWorks/HowItWorks.css';
import './FAQ.css'

const FAQ = () => {
  return (
    <div className="section-padding" id="faq">
      <Headings title="Frequently Asked Questions" text="If you're wondering if HealthBuddy is right for you ( it is ), take a look at our FAQ and send us an email if you have other questions." />

      <div className="row faq-row">

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="0">
              <div className="card-team-header"> 
              <h4>Can I create custom reminders using HealthBuddy?</h4>
              </div>
              <p>Yes! Even though HealthBuddy was designed for designed for senior reminders and safety check-ins, you can use our platform to create reminders for anything!</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="1">
              <div className="card-team-header"> 
              <h4>How do you ensure the text messages are received?</h4>
              </div>
              <p>HealthBuddy doesn’t consider a message to be received until we receive a response, and we send multiple texts before reaching out to emergency contracts.</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="2">
              <div className="card-team-header"> 
              <h4>What happens if a reminder or check-in isn’t responded to?</h4>
              </div>
              <p>We will follow-up multiple times to get a response, but once the response window passes we will alert the specified emergency contacts to inform them of the missed reminder.</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="3">
              <div className="card-team-header"> 
              <h4>Who actually does the calls and texts for HealthBuddy?</h4>
              </div>
              <p>HealthBuddy is 100% automated — we use AI-generated voices for our calls and our call and text content is powered by the latest innovations in large language models.</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="4">
              <div className="card-team-header"> 
              <h4>Can I see the responses to calls and texts sent by HealthBuddy?</h4>
              </div>
              <p>Yes! We auto-transcribe every call and make all call and text dialogue available on the HealthBuddy Dashboard, so you can have a holistic record of care.</p>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-12">
            <div className="card-team bg-color-grey blight" key="5">
              <div className="card-team-header"> 
              <h4>Is all of the information shared with HealthBuddy kept private?</h4>
              </div>
              <p>Yes. We never sell or distribute any information that is shared with HealthBuddy. We are always looking for new ways to ensure that your data has maximum security.</p>
            </div>
        </div>



      </div>
    </div>
  )
}

export default FAQ; 
