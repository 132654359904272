import React from 'react';
import './Pricing.css';
import Headings from '../../components/Headings/Headings';
import { BsArrowRight } from "react-icons/bs";

import { ReactComponent as PricingImage } from '../../assets/images/pricingImage.svg'



const Pricing = () => {
  return (
    <div className="section-padding" id="pricing">
        <Headings title="Pricing" text="With HealthBuddy, you get premium care without the premium price. An unmatched level of service that can fit into your budget." />
        <div className="row cta">
            <div className="col-lg-6 col-12">
                <h3 className="pricing-h3">Standard Subscription</h3>
                
                <h2 className="pb-4">$30 <span className="h4">per month</span></h2>
                
                <a href="#nav"><button className="pricing-btn">Join the waitlist <BsArrowRight/></button></a>
                <h4 className="pt-4">
                    What's included:
                </h4>
                <ul className="show-listing">
                    <li>HealthBuddy management dashboard</li>
                    <li>5 reminders or check-ins per day</li>
                    <li>3 emergency contacts</li>
                    <li>Easy credit-card payments</li>
                    <li>Cancel anytime</li>
                </ul>
            </div>
            <div className="col-lg-6 col-12 special-col-6">
                <PricingImage/>
            </div>
        </div>
    </div>
  )
}

export default Pricing