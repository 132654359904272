import React from 'react';
import Headings from '../../components/Headings/Headings';
// import { data } from '../../constants';
// import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';

import {ReactComponent as TokyoScheduleInBrowser} from '../../assets/images/tokyoScheduleInBrowser.svg'
import {ReactComponent as TokyoSliders} from '../../assets/images/tokyoSliders.svg'
import {ReactComponent as TokyoCharts} from '../../assets/images/tokyoCharts.svg'
import {ReactComponent as TokyoBinoculars} from '../../assets/images/tokyoBinoculars.svg'


import './Benefits.css';


const Benefits = () => {
  return (
    <div className="d-block pt-md-4" id="benefits">
      <Headings title="Member Benefits" text="We have designed HealthBuddy to be your one-stop-shop for  reminders and check-ins. You will never need to use anything else." />

      <div className="row">

        <div className="col-lg-6 col-12" key="0">
          <div className={`row blight`}>
            <div className="col-md-6 box">
                <div className="mb-3 benefit-title-container">
                    <span className="benefit-title highlight">Effortless</span> 
                    <span className="benefit-title highlight">Scheduling</span> 
               </div>
               <p>Our web dashboard makes creating reminders and check-ins is as simple as using an online calendar</p>
              </div>
              <div className="col-md-6 text-end">
                <TokyoScheduleInBrowser className="img-fluid img-services" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12" key="1">
          {/* <div className={`row bgreen`}> */}
          <div className={`row blight`}>
            <div className="col-md-6 box">
                <div className="mb-3 benefit-title-container">
                    <span className="benefit-title highlight">Flexibility and</span> 
                    <span className="benefit-title highlight">Personalization</span> 
               </div>
               <p>We provide both call and text services powered by cutting edge AI to make reminders personal and context-aware</p>
              </div>
              <div className="col-md-6 text-end">
                <TokyoSliders className="img-fluid img-services" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12" key="2">
          {/* <div className={`row bdark`}> */}
          <div className={`row blight`}>
            <div className="col-md-6 box">
                <div className="mb-3 benefit-title-container">
                    <span className="benefit-title highlight">Simple</span> 
                    <span className="benefit-title highlight">Monitoring</span> 
               </div>
               <p>Keep track of responses to reminders and other analytics to increase the effectiveness of your reminders over time</p>
              </div>
              <div className="col-md-6 text-end">
                <TokyoCharts className="img-fluid img-services" />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12" key="3">
          {/* <div className={`row bdark`}> */}
          <div className={`row blight`}>
            <div className="col-md-6 box">
                <div className="mb-3 benefit-title-container">
                    <span className="benefit-title highlight">Proactive</span> 
                    <span className="benefit-title highlight">Alerting</span> 
               </div>
               <p>The software works with you to analyze data, surfacing trends and information that may need your attention.</p>
              </div>
              <div className="col-md-6 text-end">
                <TokyoBinoculars className="img-fluid img-services" />
              </div>
            </div>
          </div>

      </div>
    </div>
  )
}

export default Benefits