import NavigationBlank from '../components/NavigationBlank/NavigationBlank'
import Terms from '../components/Terms/Terms'

const TermsPage: React.FC = () => {
    return(
      <div className="container">
        <NavigationBlank />
          <Terms/>
      </div>
    )
  }

export default TermsPage;