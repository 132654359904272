import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
// import { data } from '../../constants';
import Headings from '../../components/Headings/Headings';
import './Services.css';

const Process = () => {
  return (
    <div className="section-padding" id="services">
      <Headings title="Services " text="Our services are designed for seniors and people who live alone to provide assurance that someone will check on them regularly." />

    <div className="static-accordion">
        
        <div className="static-accordion-item">
            <div className="static-accordion-header">
                <div>
                    <div className="static-accordion-padding">
                        <span>01</span>
                        Medication, meal, and activity reminders via call or text
                    </div>
                </div>
            </div>
            <div className="static-accordion-body static-accordion-padding">
                <p>HealthBuddy's Scheduled Reminder Service harnesses advanced AI to deliver the personalized attention of a live care call with the affordability of automated service. Tailor-made for those who need daily reminders like seniors or anyone who benefits from regular check-ins, our service offers timely reminders for medications, meals, activities, and more —coupled with the warmth of a friendly chat. It's an ideal solution for individuals living alone or for families who might be geographically distant or managing busy schedules, ensuring their loved ones are cared for and connected. If a response isn't received or a potential issue is detected, our system immediately notifies designated contacts, providing critical peace of mind. HealthBuddy stands out by offering high-quality, reliable, and compassionate care, making daily connections more accessible and affordable than ever.</p>
            </div>
        </div>

        <div className="static-accordion-item">
            <div className="static-accordion-header">
                <div>
                    <div className="static-accordion-padding">
                        <span>02</span>
                        Safety check-ins via call or text
                    </div>
                </div>
            </div>
            <div className="static-accordion-body static-accordion-padding">
                <p>With HealthBuddy's Monitored Check-In service, you're in control of your daily safety routine. This service is perfect for those who value the freedom to choose when and how they check in, whether it's first thing in the morning or later in the day. Simply make your check-in call within your selected time window, and our system, recognizing calls from various numbers, will confirm your check-in. If we don't hear from you within the agreed window, our vigilant team will reach out to ensure your safety or contact your emergency contacts as a precaution. This service is especially suitable for individuals who may not need regular interaction but still want the reassurance of a safety net, giving both users and their families the comfort of knowing that someone is always keeping a watchful eye.</p>
            </div>
        </div>

    </div>
      {/* <Accordion> 
        <Accordion.Item eventKey="0" key="0">
            <Accordion.Header>
                <div>
                    <span>01</span>
                    Research and Strategy Development
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <p> 
                    Lorem Ipsum.
                </p>
            </Accordion.Body>
          </Accordion.Item>

      </Accordion> */}
    </div>
  )
}

export default Process