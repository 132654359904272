import React, { useCallback, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../Navigation/Navigation.css';

import {ReactComponent as HealthBuddyLogo} from '../../assets/images/logoImage.svg'

interface NavbarProps {}

const NavigationBlank: React.FC<NavbarProps> = () => {
    return(
        <Navbar collapseOnSelect expand="lg">
            <Container className="nav-container">
                <Navbar.Brand href="/">
                    <HealthBuddyLogo />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        {/* <Nav.Link href='#how-it-works'>How it Works</Nav.Link>
                        <Nav.Link href='#benefits'>Member Benefits</Nav.Link>
                        <Nav.Link href='#services'>Services</Nav.Link>
                        <Nav.Link href='#pricing'>Pricing</Nav.Link>
                        <Nav.Link href='#faq'>FAQ</Nav.Link>
                        <Nav.Link href='#contact'>Contact</Nav.Link>
                        <Nav.Link href='/login'>Login</Nav.Link> */}
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavigationBlank; 