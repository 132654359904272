import React from 'react';
import './Headings.css';

type HeadingsProps = {
  title: string;
  text: string;
};

const Headings: React.FC<HeadingsProps> = ({ title, text }) => {
    return (
        <div className="headings">
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    );
}

export default Headings;